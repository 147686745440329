<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          User Summary
        </b-card-title>
        <b-card-sub-title>유저 통계</b-card-sub-title>
      </div>
    </b-card-header>
    <b-card-body>
      <div
        class="transaction-item"
        @mouseover="isHovered = true"
        @mouseout="isHovered = false"
        @click="goRoute('apps-users-list-now')"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              src="user"
              :text="avatarText('user')"
              :variant="`${resolveUserRoleVariant('user')}`"
            />
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              유저 현재 접속
            </h6>
            <small>User</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :style="{
            color: true ? '#33d918' : (true === false ? '#54c4ff' : '#f1c346')
          }"
        >
          {{ this.roleNowCounts.user }}
        </div>
      </div>

      <div
        class="transaction-item"
        @mouseover="isHovered = true"
        @mouseout="isHovered = false"
        @click="goRoute('users-connect-logs')"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              src="user"
              :text="avatarText('user')"
              :variant="`${resolveUserRoleVariant('user')}`"
            />
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              유저 금일 누적 접속
            </h6>
            <small>User</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :style="{
            color: true ? '#33d918' : (true === false ? '#54c4ff' : '#f1c346')
          }"
        >
          {{ roleTodayTotalCounts.user }}
        </div>
      </div>

      <div
        class="transaction-item"
        @mouseover="isHovered = true"
        @mouseout="isHovered = false"
        @click="goRoute('apps-users-list-now')"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              src="branch"
              :text="avatarText('branch')"
              :variant="`${resolveUserRoleVariant('branch')}`"
            />
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              총판 현재 접속
            </h6>
            <small>Branch</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :style="{
            color: true ? '#33d918' : (true === false ? '#54c4ff' : '#f1c346')
          }"
        >
          {{ this.roleNowCounts.branch }}
        </div>
      </div>

      <div
        class="transaction-item"
        @mouseover="isHovered = true"
        @mouseout="isHovered = false"
        @click="goRoute('users-connect-logs')"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              src="branch"
              :text="avatarText('branch')"
              :variant="`${resolveUserRoleVariant('branch')}`"
            />
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              총판 금일 누적 접속
            </h6>
            <small>Branch</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :style="{
            color: true ? '#33d918' : (true === false ? '#54c4ff' : '#f1c346')
          }"
        >
          {{ roleTodayTotalCounts.branch }}
        </div>
      </div>

      <div
        class="transaction-item"
        @mouseover="isHovered = true"
        @mouseout="isHovered = false"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              src="admin"
              :text="avatarText('admin')"
              :variant="`${resolveUserRoleVariant('admin')}`"
            />
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              어드민 현재 접속
            </h6>
            <small>Admin</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :style="{
            color: true ? '#33d918' : (true === false ? '#54c4ff' : '#f1c346')
          }"
        >
          {{ this.roleNowCounts.admin }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BCardSubTitle,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { createNamespacedHelpers } from 'vuex'
import { FETCH_SIGNIN_LOGS } from '@/store/logs/action'
import { FETCH_USERS } from '@/store/users/action'

import * as moment from 'moment-timezone'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'

const logStore = createNamespacedHelpers('logStore')
const userStore = createNamespacedHelpers('userStore')

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BCardSubTitle,
  },
  props: {
    siteSelected: String,
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      connectedUsers: [],
      isHovered: false,
      roleNowCounts: {},
      roleTodayTotalCounts: {},
      transactionData: [
        {
          mode: '유저 현재접속',
          types: 'Deposit',
          avatar: 'CornerRightDownIcon',
          avatarVariant: '',
          payment: '45',
          deduction: true,
          role: 'user',
        },
        {
          mode: '유저 금일 접속누적',
          types: 'Exchange',
          avatar: 'CornerRightUpIcon',
          avatarVariant: '',
          payment: '182',
          deduction: true,
          role: 'user',
        },
        {
          mode: '총판 현재 접속',
          types: 'Betting',
          avatar: 'BoldIcon',
          avatarVariant: '',
          payment: '7',
          deduction: true,
          role: 'branch',
        },
        {
          mode: '총판 금일 접속누적',
          types: 'Win',
          avatar: 'AwardIcon',
          avatarVariant: '',
          payment: '30',
          deduction: true,
          role: 'branch',
        },
        {
          mode: '어드민 현재접속',
          types: 'Profit',
          avatar: 'DollarSignIcon',
          avatarVariant: '',
          payment: '4',
          deduction: true,
          role: 'admin',
        },
      ],
    }
  },
  watch: {
    siteSelected: {
      handler(event) {
        // console.log('event ::', event)
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
    rangePicker() {
      const range = this.rangePicker.split(' to ')
      this.periodFrom = range[0]
      this.periodTo = range[1]
      this.fetchData()
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
  computed: {
    ...logStore.mapGetters({
      fetchSigninLogsItems: 'fetchSigninLogsItems',
    }),
    ...userStore.mapGetters({
      fetchUsers: 'fetchUsers',
    }),
  },
  created() {
    this.$socket.on('connectedUsers', data => {
      // console.log('Connected Users: ', data)
      this.connectedUsers = data
      this.countNowUser()
      this.fetchData()
    })
  },
  mounted() {
    this.$socket.emit('getConnectedUsers', {
      userid: this.userData.userid,
    }, data => {
      // console.log('getConnectedUsers: ', data)
      this.connectedUsers = data
      this.countNowUser()
    })
    this.fetchData()
  },
  methods: {
    ...logStore.mapActions({
      $fetchSigninLogs: FETCH_SIGNIN_LOGS,
    }),
    ...userStore.mapActions({
      $fetchUsers: FETCH_USERS,
    }),
    async fetchData() {
      const mySite = this.siteSelected
      const periodFrom = moment().startOf('day').format(fmt1)
      const periodTo = moment().endOf('day').format(fmt1)
      await this.$fetchUsers({
        site: mySite,
        userid: this.userData.userid,
      })
      await this.$fetchSigninLogs({
        site: mySite,
        periodFrom,
        periodTo,
      })
      // console.log('this.fetchSigninLogsItems:', this.fetchSigninLogsItems)
      // console.log('this.fetchUsers:', this.fetchUsers)
      await this.countTodayTotalUser()
    },
    resolveUserRoleVariant(role) {
      if (role === 'user') return 'secondary'
      if (role === 'branch') return 'warning'
      if (role === 'admin') return 'primary'
      if (role === 'godfather') return 'light-danger'
      return 'secondary'
    },
    countNowUser() {
      this.roleNowCounts = {
        user: 0,
        admin: 0,
        branch: 0,
      }
      // 데이터셋 배열을 순회하면서 count
      this.connectedUsers.forEach(user => {
        const { role } = user
        if (Object.prototype.hasOwnProperty.call(this.roleNowCounts, role)) {
          this.roleNowCounts[role] += 1
        }
      })
    },

    async countTodayTotalUser() {
      this.roleTodayTotalCounts = {
        user: 0,
        admin: 0,
        branch: 0,
      }
      // console.log('22this.fetchSigninLogsItems:', this.fetchSigninLogsItems)
      //   console.log('22this.fetchUsers:', this.fetchUsers)
      // 사용자 ID를 기반으로 fetchSigninLogsItems와 fetchUsers 배열을 비교하여 새로운 역할 컬럼을 추가
      this.fetchSigninLogsItems.forEach(item => {
        const { userid } = item
        const matchedUsers = this.fetchUsers.filter(user => user.userid === userid)
        // console.log('matchedUsers:::', matchedUsers)
        // console.log('this.fetchUsers:::', this.fetchUsers)
        if (matchedUsers.length > 0) {
          if (matchedUsers[0].role === 'user') {
            this.roleTodayTotalCounts.user += 1
            // console.log('roleTodayTotalCounts.user::', this.roleTodayTotalCounts.user)
          } else if (matchedUsers[0].role === 'branch') {
            this.roleTodayTotalCounts.branch += 1
            // console.log('roleTodayTotalCounts.branch:::', this.roleTodayTotalCounts.branch)
          }
        }
      })
    },
    goRoute(list) {
      this.$router.push({ name: list }).catch(() => {})
    },
  },
}
</script>

<style scoped>
.transaction-item {
  transition: background-color 0.3s;
}

.transaction-item:hover {
  background-color: #252B3D; /* 호버 효과에 적용할 배경색상 */
}
</style>
