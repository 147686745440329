<template>
  <div>
    <b-row>
      <span class="float-md-right text-primary p-1">
        <b-badge v-for="(authSite, index) in authSite"
                 :key="index"
                 type="button"
                 :variant="buttonVariant(index)"
                 style="margin-right: 3px; font-size: xx-small; padding: 8px; width: 5rem"
                 @click="selectSite(index, authSite)"
        >
          {{ authSite }}
        </b-badge>
      </span>
    </b-row>
    <b-row>
      <!-- Profit Summary(수익 통계) -->
      <b-col lg="4" md="6">
        <profit-summary :site-selected="siteSelected"/>
      </b-col>
      <!-- User Summary(유저 통계) -->
      <b-col lg="4" md="6">
        <user-summary :site-selected="siteSelected"/>
      </b-col>
      <!-- Admin Notice Dashboard(관리자 공지사항 대쉬보드) -->
      <b-col lg="4" md="12">
        <admin-notice-dashboard :site-selected="siteSelected"/>
      </b-col>
      <!-- Cumulative Profit Chart(누적 수익 차트) -->
<!--      <b-col lg="8" md="12">-->
<!--        <cumulative-profit-chart :site-selected="siteSelected"/>-->
<!--      </b-col>-->
<!--      &lt;!&ndash; Rank Chart(총판/유저 수익기여순위) &ndash;&gt;-->
<!--      <b-col lg="4" md="12">-->
<!--        <rank-chart :site-selected="siteSelected"/>-->
<!--      </b-col>-->
<!--      &lt;!&ndash; Deposit Exchange Chart(충/환전 라인차트) &ndash;&gt;-->
<!--      <b-col lg="6" md="12">-->
<!--        <deposit-exchange-chart :site-selected="siteSelected"/>-->
<!--      </b-col>-->
<!--      &lt;!&ndash; Revenue Chart(일별 수익 바차트) &ndash;&gt;-->
<!--      <b-col lg="6" md="12">-->
<!--        <revenue-chart :site-selected="siteSelected"/>-->
<!--      </b-col>-->
<!--      &lt;!&ndash; Betting Chart(배팅 바차트) &ndash;&gt;-->
<!--      <b-col lg="8" md="12">-->
<!--        <betting-chart :site-selected="siteSelected"/>-->
<!--      </b-col>-->
<!--      &lt;!&ndash; Betting Category(배팅 유형차트) &ndash;&gt;-->
<!--      <b-col lg="4" md="12">-->
<!--        <betting-category :site-selected="siteSelected"/>-->
<!--      </b-col>-->
<!--      &lt;!&ndash; User Connection Scatter Chart(유저접속 산점차트) &ndash;&gt;-->
<!--      <b-col lg="12" md="12">-->
<!--        <user-connection-scatter-chart :site-selected="siteSelected"/>-->
<!--      </b-col>-->
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BBadge, BButton,
} from 'bootstrap-vue'
import ProfitSummary from './dashboard/ProfitSummary.vue'
import UserSummary from './dashboard/UserSummary.vue'
import AdminNoticeDashboard from './dashboard/AdminNoticeDashboard.vue'
import DepositExchangeChart from './dashboard/DepositExchangeChart.vue'
import RankChart from './dashboard/RankChart.vue'
import BettingChart from './dashboard/BettingChart.vue'
import BettingCategory from './dashboard/BettingCategory.vue'
import CumulativeProfitChart from './dashboard/CumulativeProfitChart.vue'
import UserConnectionScatterChart from './dashboard/UserConnectionScatterChart.vue'
import RevenueChart from './dashboard/RevenueChart.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createNamespacedHelpers } from 'vuex'
import { FETCH_SET_ALARMS_ARRAY } from '@/store/settings/action'
import store from '@/store'

const settingsStore = createNamespacedHelpers('settingsStore')
const footerStore = createNamespacedHelpers('footerStore')

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    ProfitSummary,
    UserSummary,
    AdminNoticeDashboard,
    DepositExchangeChart,
    RankChart,
    BettingChart,
    BettingCategory,
    CumulativeProfitChart,
    UserConnectionScatterChart,
    RevenueChart,
  },
  computed: {
    ...settingsStore.mapGetters({
      fetchSetSiteAdmin: 'fetchSetSiteAdmin',
      fetchSetAlarmsArray: 'fetchSetAlarmsArray',
    }),
    ...footerStore.mapState({
      sss: 'selectedSiteIndex',
    }),
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: localStorage.getItem('authSiteSelected'),
      siteAdminData: null,
      selectedSiteIndex: null,

      audio: null, // audio 객체를 저장할 변수
      isPlaying: false, // 음악이 재생 중인지 여부를 나타내는 변수
      playCount: 0, // 추가: 재생 횟수를 기록하는 변수
      maxPlayCount: 2, // 추가: 최대 재생 횟수
      alarmSettings: [], // alarmSettings 초기화
    }
  },
  mounted() {
    this.audio = this.$refs.backgroundAudio
    this.requestAudioPermission()
    this.fetchData()
    this.socketListener()
  },
  methods: {
    ...settingsStore.mapActions({
      $fetchSetAlarmsArray: FETCH_SET_ALARMS_ARRAY,
    }),
    async requestAudioPermission() {
      // 페이지 로드 시 사용자에게 Notification 권한 요청
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          // 사용자에게 알림
          this.showToast('알림 권한이 허용되었습니다.', '', 'top-center')

          // 오디오 재생
          if (this.audio) {
            this.audio.play().then(() => {
              console.log('오디오가 재생됩니다.')
            }).catch(error => {
              console.log('오디오 재생 중 에러 발생:', error)
            })
          }
        } else {
          console.log('알림 권한이 거부되었습니다.')
        }
      }).catch(error => {
        console.log('알림 권한 요청 중 에러 발생:', error)
      })
    },
    async fetchData() {
      this.selectedSiteIndex = 0
      this.siteSelected = this.authSite[0]

      // Sound Alarms Setting
      await this.$fetchSetAlarmsArray({
        site: this.authSite,
      })
      // console.log('$fetchSetAlarmsArray', this.fetchSetAlarmsArray)
      const alarmsData = this.fetchSetAlarmsArray
      alarmsData.forEach(alarm => {
        // 사이트별로 alarmSettings 객체를 동적으로 업데이트합니다.
        if (!this.alarmSettings[alarm.site]) {
          this.alarmSettings[alarm.site] = {} // 새로운 사이트에 대한 객체 초기화
        }
        this.alarmSettings[alarm.site][alarm.alarmsSound] = {
          useYn: alarm.useYn === 'Y', // 'Y'면 true, 그 외는 false로 변환
          alarmCnt: alarm.alarmsCnt,
          playInterval: alarm.alarmsInterval,
        }
      })
      console.log('alarmsSettings : ', this.alarmSettings)
    },
    selectSite(index, authSiteSelected) {
      this.$store.commit('footerStore/setSelectedSiteIndex', index)
      this.$store.commit('footerStore/setSelectedSite', authSiteSelected)
      localStorage.setItem('authSiteSelected', authSiteSelected)
      this.siteSelected = authSiteSelected
    },
    buttonVariant(index) {
      return index === this.sss ? 'success' : 'dark'
    },
    cashFormat(value) {
      return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    autoplayVideo(alarmType, site) { // site 매개변수 추가
      // 전달된 site에 맞는 알람 설정을 가져옴
      const siteSettings = this.alarmSettings[site] // 변경된 부분
      const settings = siteSettings ? siteSettings[alarmType] : null // 변경된 부분

      if (settings && settings.useYn) {
        this.setupSound(alarmType, settings)
      }
    },
    setupSound(alarmType, settings) {
      this.audio = new Audio(`https://agress-assets.s3.ap-northeast-2.amazonaws.com/sounds/${alarmType}.mp3`)
      this.audio.addEventListener('ended', () => this.replaySound(settings))
      const result = this.audio.play()
      this.isPlaying = true
      return result
    },
    replaySound(settings) {
      this.playCount += 1
      console.log('settings.alarmCnt :: ', settings.alarmCnt)
      console.log('settings.playInterval :: ', settings.playInterval)
      if (this.playCount < settings.alarmCnt) {
        setTimeout(() => {
          this.audio.play()
        }, settings.playInterval * 1000)
      } else {
        this.stopSound()
      }
    },
    stopSound() {
      if (this.audio) {
        this.audio.pause()
        this.audio.currentTime = 0
        this.audio.removeEventListener('ended', this.replaySound)
        this.audio = null
        this.isPlaying = false
      }
    },
    alarmMsg(target) {
      const msg = {
        id: Math.floor(Math.random() * 1000) + 1,
        title: target.title,
        avatar: target.avatar,
        subtitle: target.subtitle,
        type: target.type,
        route: target.route,
      }
      store.dispatch(target.target, msg)
    },
    socketListener() {
      // Socket 리스너 설정
      console.log('listener on')

      // 1.충전 알람
      this.$socket.on('requestDepositToAdmin', data => {
        console.log('Socket: requestDepositToAdmin')
        // 사운드를 실행합니다.
        this.playCount = 0 // reset play count
        // this.autoplayVideo('deposit')
        this.autoplayVideo('deposit', data.site) // 변경된 부분: data.site를 전달하여 사이트별 설정 사용

        // 알람 전송
        // site: requestDepositDTO.site,
        // user: requestDepositDTO.requestId,
        // title: `${requestDepositDTO.requestId}(${requestDepositDTO.requestNickname})님 충전 요청`,
        // subtitle: `${requestDepositDTO.requestCash}`, // info
        // username: `${requestDepositDTO.requestName}`,
        const target = {
          avatar: '충전',
          title: `${data.title}`,
          subtitle: `${data.site}사이트 ${data.user}님이 ${this.cashFormat(data.subtitle)}원 충전 신청하였습니다.`,
          type: 'danger',
          target: 'notification/addNotification',
          route: '/payment/deposit-list',
        }
        this.alarmMsg(target)
      })
      // 2.환전 알람
      this.$socket.on('requestExchangeToAdmin', data => {
        console.log('Socket: requestExchangeToAdmin')
        // 사운드를 실행합니다.
        this.playCount = 0 // reset play count
        this.autoplayVideo('exchange', data.site)
        // 알람 전송
        const target = {
          avatar: '환전',
          title: `${data.title}`,
          subtitle: `${data.site}사이트 ${data.user}님이 ${this.cashFormat(data.subtitle)}원 환전 신청하였습니다.`,
          type: 'danger',
          target: 'notification/addNotification',
          route: '/payment/exchange-list',
        }
        this.alarmMsg(target)
      })
      // 3.회원 문의(쪽지)
      this.$socket.on('requestAlarmsMessageAdmin', data => {
        console.log('Socket: requestAlarmsMessageAdmin')
        // 사운드를 실행합니다.
        this.playCount = 0
        this.autoplayVideo('message', data.site)
        // 알람 전송
        const target = {
          avatar: '문의',
          title: data.title,
          subtitle: `${data.site}사이트 ${data.user}님이 "${data.subtitle}" 문의(쪽지) 하였습니다.`,
          type: 'primary',
          target: 'notification/addNotification',
          route: '/boards/request-alarms',
        }
        this.alarmMsg(target)
      })
      // 4.신규 회원가입 알람
      this.$socket.on('requestSignupToAdmin', data => {
        console.log('Socket: requestSignupToAdmin')
        // 출금 사운드를 실행합니다.
        this.playCount = 0
        this.autoplayVideo('signUp', data.site)

        // 알람 전송
        const target = {
          avatar: '신규',
          title: data.title,
          subtitle: `${data.subtitle} 정보로 요청했습니다.`,
          type: 'primary',
          target: 'notification/addNotification',
          route: '/boards/request-alarms',
        }
        this.alarmMsg(target)
      })
      // 5.게임적중 환급 알람
      this.$socket.on('requestUserBetConfirmToAdmin', data => {
        // 환전 사운드를 실행합니다.
        this.playCount = 0 // reset play count
        this.autoplayVideo('betConfirm', data.site)

        // 알람 전송
        const target = {
          title: data.title,
          avatar: '환급',
          subtitle: data.subtitle,
          type: 'danger',
          target: 'notification/addNotification',
          route: '/game/refund-hit-result',
        }
        this.alarmMsg(target)
      })
      // 6.총판 출금요청 알람
      this.$socket.on('requestBranchCashOutToAdmin', data => {

        console.log('requestBranchCashOutToAdmin ::: ', data)
        // 총판출금 사운드를 실행합니다.
        this.playCount = 0 // reset play count
        this.autoplayVideo('branchCashOut', data.site)

        // 알람 전송
        const target = {
          avatar: '총환',
          title: `${data.title}`,
          subtitle: `${data.site}사이트 ${data.user}님이 ${this.cashFormat(data.subtitle)}원 총판 출금 신청하였습니다.`,
          type: 'danger',
          target: 'notification/addNotification',
          route: '/payment/exchange-list',
        }
        this.alarmMsg(target)
      })
      // 7.고객 배팅 알람
      this.$socket.on('alarmsUserBetHighToAdmin', data => {
        // 환전 사운드를 실행합니다.
        this.playCount = 0 // reset play count
        this.autoplayVideo('betHigh', data.site)

        // 알람 전송
        const target = {
          avatar: '고액',
          title: `${data.title}`,
          subtitle: `${data.subtitle}배당/${this.cashFormat(data.etc)}원 배팅`,
          type: 'danger',
          target: 'notification/addNotification',
          route: '/payment/exchange-list',
        }
        this.alarmMsg(target)
      })
      // 8.관리자 공지사항 알람
      this.$socket.on('alarmsNoticeToAdmin', data => {
        // 환전 사운드를 실행합니다.
        this.playCount = 0 // reset play count
        this.autoplayVideo('noticeAdmin', data.site)

        // 알람 전송
        const target = {
          title: data.title,
          avatar: '공지',
          subtitle: `${data.subtitle}공지/${(data.site)}공지사항`,
          type: 'danger',
          target: 'notification/addNotification',
          route: '/boards/notice-admin',
        }
        console.log('target:::::', target)
        this.alarmMsg(target)
      })
      // 9.비회원 문의 알람
      this.$socket.on('requestGuestQuestionToAdmin', data => {
        // 출금 사운드를 실행합니다.
        this.playCount = 0
        this.autoplayVideo('guestMessage', data.site)

        // 알람 전송
        const target = {
          title: `${data.userid}님 문의요청`,
          avatar: '비회원',
          subtitle: `${data.userid}님이 문의요청을 했습니다.`,
          type: 'dark',
          target: 'notification/addNotification',
          route: '/boards/request-alarms',
        }
        this.alarmMsg(target)
      })
      // 기타: 상단메뉴 알람 실시간 처리 카운트 공유
      this.$socket.on('updateTopMenuNotification', data => {
        // 환전 사운드를 실행합니다.
        this.playCount = 0 // reset play count
        this.autoplayVideo('adminConfirm', data.site)

        // 알람 전송
        const target = {
          title: '처리',
          avatar: data.type,
          subtitle: '',
          type: 'danger',
          target: 'notification/addNotification',
          route: '/boards/notice-admin',
        }
        this.alarmMsg(target)
      })
    },
    async sleep(ms) {
      const wakeUpTime = Date.now() + ms
      while (Date.now() < wakeUpTime) {}
    },
    showToast(title, subtitle, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text: subtitle,
          icon: 'BellIcon',
          variant: 'danger',
        },
      },
      {
        position,
        // timeout: 8000,
      })
    },
  },
}
// async function getIpClient() {
//   try {
//     const response = await axios.get('https://ipinfo.io/json');
//     console.log(response);
//   } catch (error) {
//     console.error(error);
//   }
// }
//
// async function getBrowserClient() {
//   try {
//     const userInfo = Bowser.parse(window.navigator.userAgent)
//     //console.log(userInfo);
//     console.log(userInfo);
//   } catch (error) {
//     console.error(error);
//   }
// }
</script>

<style lang="scss">
</style>
