<template>
  <b-card
    no-body
  >
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Revenue Chart
        </b-card-title>
        <b-card-sub-title>일별 수익 바차트</b-card-sub-title>
      </div>
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range', onChange: handleDateChange }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          style="width: 210px;"
          placeholder="YYYY-MM-DD"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <!-- chart -->
      <vue-apex-charts
        id="revenue-report-chart"
        type="bar"
        height="400"
        :options="revenueReport.chartOptions"
        :series="revenueReport.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import * as moment from 'moment-timezone'
import flatPickr from 'vue-flatpickr-component'

import { createNamespacedHelpers } from 'vuex'
import { FETCH_REQUEST_ALARMS_DEPOSIT_FINISH, FETCH_REQUEST_ALARMS_EXCHANGE_FINISH } from '@/store/notice/action'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm:ss'

const noticeStore = createNamespacedHelpers('noticeStore')

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    siteSelected: String,
  },
  computed: {
    ...noticeStore.mapGetters({
      fetchRequestAlarmsDepositFinish: 'fetchRequestAlarmsDepositFinish',
      fetchRequestAlarmsExchangeFinish: 'fetchRequestAlarmsExchangeFinish',
    }),
  },
  watch: {
    siteSelected: {
      handler(event) {
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
    rangePicker() {
      const range = this.rangePicker.split(' to ')
      this.periodFrom = range[0]
      this.periodTo = range[1]
      // this.fetchData()
    },
  },
  created() {
    // 누적 수익 차트 소켓 조회
    this.$socket.on('requestAlarmsDepositRevenueChartDashBoard', data => {
      // console.log('requestAlarmsDepositRevenueChartDashBoard: ', data)
      this.processNextChartData(data) // 다음 작업을 호출
    })
    this.$socket.on('requestAlarmsExchangeRevenueChartDashBoard', data => {
      // console.log('requestAlarmsExchangeRevenueChartDashBoard: ', data)
      this.processNextChartData(data) // 다음 작업을 호출
    })
  },
  mounted() {
    this.fetchData()
  },
  data() {
    const startOfMonth = moment(new Date()).startOf('month').format(fmt1)
    const endOfMonth = moment(new Date()).endOf('month').format(fmt1)
    const monthDays = []
    for (let date = moment(startOfMonth); date.isSameOrBefore(endOfMonth); date.add(1, 'day')) {
      monthDays.push(date.format('M/D'))
    }
    return {
      periodFrom: startOfMonth,
      periodTo: endOfMonth,
      revenueReport: {
        series: [
          {
            name: '수익',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          // grid: {
          //   padding: {
          //     // top: -10,
          //     // bottom: -10,
          //   },
          //   // yaxis: {
          //   //   lines: { show: false },
          //   // },
          // },
          xaxis: {
            // type: 'datetime',
            categories: [],
            labels: {
              format: 'M/d',
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          // legend: {
          //   show: true,
          //   position: 'top',
          //   horizontalAlign: 'left',
          //   fontSize: '14px',
          //   fontFamily: 'Montserrat',
          // },
          dataLabels: {
            enabled: false,
          },
          colors: ['#00cfe8'],
          plotOptions: {
            bar: {
              endingShape: 'rounded',
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              formatter(value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              },
              style: {
                colors: '#6E6B7B',
                fontSize: '0.86rem',
                fontFamily: 'Montserrat',
              },
            },
          },
          // tooltip: {
          //   shared: true,
          // },
        },
      },
      rangePicker: [startOfMonth, endOfMonth],
    }
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchRequestAlarmsDepositFinish: FETCH_REQUEST_ALARMS_DEPOSIT_FINISH,
      $fetchRequestAlarmsExchangeFinish: FETCH_REQUEST_ALARMS_EXCHANGE_FINISH,
    }),
    async fetchData() {
      if (this.periodTo === undefined) {
        this.periodTo = this.periodFrom
      }
      const periodFromWithTime = moment(this.periodFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      const periodToWithTime = moment(this.periodTo).endOf('day').format('YYYY-MM-DD HH:mm:ss')

      // 시, 분, 초가 추가된 날짜를 다시 변수에 할당
      this.periodFrom = periodFromWithTime
      this.periodTo = periodToWithTime

      await this.fetchDepositFinish()
      await this.fetchExchangeFinish()
      await this.sprepareChartData()
    },
    async fetchDepositFinish() {
      const mySite = this.siteSelected
      await this.$fetchRequestAlarmsDepositFinish({
        site: mySite,
        confirmed: 'CONFIRMED',
        periodFrom: this.periodFrom,
        periodTo: this.periodTo,
      })
      // console.log('$fetchRequestAlarmsDepositFinish::', this.fetchRequestAlarmsDepositFinish)
    },
    async fetchExchangeFinish() {
      const mySite = this.siteSelected
      await this.$fetchRequestAlarmsExchangeFinish({
        site: mySite,
        confirmed: 'CONFIRMED',
        periodFrom: this.periodFrom,
        periodTo: this.periodTo,
      })
      // console.log('$fetchRequestAlarmsExchangeFinish::', this.fetchRequestAlarmsExchangeFinish)
    },
    // 데이터 가공
    async sprepareChartData() {
      // startOfMonth부터 endOfMonth까지의 depositFinish 데이터 필터링
      const filteredDepositFinish = this.fetchRequestAlarmsDepositFinish.filter(entry => moment(entry.confirmAt).isBetween(this.periodFrom, this.periodTo, null, '[]'))

      // startOfMonth부터 endOfMonth까지의 exchangeFinish 데이터 필터링
      const filteredExchangeFinish = this.fetchRequestAlarmsExchangeFinish.filter(entry => moment(entry.confirmAt).isBetween(this.periodFrom, this.periodTo, null, '[]'))

      // startOfMonth부터 endOfMonth까지의 날짜 목록 생성
      const dateRange = []
      const currentDate = moment(this.periodFrom)
      while (currentDate.isSameOrBefore(this.periodTo)) {
        dateRange.push(currentDate.format('M/D'))
        currentDate.add(1, 'day')
      }

      // depositFinish에서 confirmAt requestCash를 추출하여 가공
      const depositData = dateRange.map(date => {
        const matchingEntries = filteredDepositFinish.filter(entry => moment(entry.confirmAt).format('M/D') === date)
        const totalRequestCash = matchingEntries.reduce((sum, entry) => sum + entry.requestCash, 0)
        return {
          x: date,
          y: totalRequestCash,
        }
      })

      // exchangeFinish에서 confirmAt requestCash를 추출하여 가공
      const exchangeData = dateRange.map(date => {
        const matchingEntries = filteredExchangeFinish.filter(entry => moment(entry.confirmAt).format('M/D') === date)
        const totalRequestCash = matchingEntries.reduce((sum, entry) => sum + entry.requestCash, 0)
        // const totalRequestCash = matchingEntries.reduce((sum, entry) => sum + entry.requestCash, 0)
        return {
          x: date,
          y: totalRequestCash,
        }
      })

      // depositData와 exchangeData에서 x 값이 같은 항목들끼리 뺀 값을 새로운 데이터로 생성
      const differenceData = depositData.map(depositEntry => {
        const correspondingExchangeEntry = exchangeData.find(entry => entry.x === depositEntry.x)
        const difference = depositEntry.y - correspondingExchangeEntry.y
        return {
          x: depositEntry.x,
          y: difference,
        }
      })
      // lineAreaChartSpline의 series에 데이터 적용
      this.revenueReport.series = [
        {
          name: '수익',
          data: differenceData,
        },
      ]
    },
    async processNextChartData(data) {
      await this.sprepareChartData()
    },
    handleDateChange(selectedDates, dateStr, instance) {
      this.$nextTick(() => {
        // selectedDates 배열의 길이가 2인 경우에만 fetchData 함수 호출
        if (selectedDates.length === 2) {
          this.fetchData()
        }
      })
    },
  },
}
</script>
