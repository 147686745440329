<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Betting Category
        </b-card-title>
        <b-card-sub-title>배팅 유형차트</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range', onChange: handleDateChange }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          style="width: 210px;"
          placeholder="YYYY-MM-DD"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <div class="pie-text text-center">
        <span class="font-weight-bold">Total</span>
        <h4 class="font-weight-bolder single-line-text font-medium-2">
          ₩ {{ allBetCash.toLocaleString() }}
        </h4>
      </div>

      <!-- echart -->
<!--      <div class="d-flex justify-content-center">-->
        <app-echart-doughnut :series="series" />
<!--      </div>-->
    </b-card-body>
  </b-card>
</template>


<script>
import {
  BCard, BCardBody, BCardHeader, BCardSubTitle, BCardTitle,
} from 'bootstrap-vue'
import AppEchartDoughnut from '@core/components/charts/echart/AppEchartDoughnut.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

import * as moment from 'moment-timezone'

import { createNamespacedHelpers } from 'vuex'

import {
  FETCH_REQUEST_ALARMS_BET_FINISH,
} from '@/store/notice/action'
import { avatarText } from '@core/utils/filter'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

const noticeStore = createNamespacedHelpers('noticeStore')

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    AppEchartDoughnut,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    siteSelected: String,
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const startOfMonth = moment(new Date()).startOf('month').format(fmt1)
    const endOfMonth = moment(new Date()).endOf('month').format(fmt1)
    const monthDays = []

    // 'startOfMonth'부터 'endOfMonth'까지의 각 날짜를 'MM-DD' 형식으로 배열에 추가
    for (let date = moment(startOfMonth); date.isSameOrBefore(endOfMonth); date.add(1, 'day')) {
      monthDays.push(date.format('M/D'))
    }
    return {
      periodFrom: startOfMonth,
      periodTo: endOfMonth,
      allBetCash: 0,
      series: [
        {
          name: '총 배팅 금액',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          // itemStyle: {
          //   borderRadius: 10,
          //   borderColor: '#fff',
          //   borderWidth: 1,
          // },
          label: {
            show: false,
            position: 'center',
          },
          labelLine: {
            show: false,
          },
          data: [],
        },
      ],
      rangePicker: [startOfMonth, endOfMonth],
    }
  },
  computed: {
    ...noticeStore.mapGetters({
      fetchRequestAlarmsBetFinish: 'fetchRequestAlarmsBetFinish',
    }),
  },
  watch: {
    siteSelected: {
      handler(event) {
        // console.log('event ::', event)
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
    rangePicker() {
      const range = this.rangePicker.split(' to ')
      this.periodFrom = range[0]
      this.periodTo = range[1]
      // this.fetchData()
    },
  },
  created() {
    // Doughtnut Chart Dashboard
    this.$socket.on('requestAlarmsBetDoughnutDashboard', data => {
      console.log('requestAlarmsBetDoughnutDashboard:::', data)
      this.processNextChartData(data)
    })
    this.$socket.on('requestAlarmsBetDoughnutCancelDashboard', data => {
      console.log('requestAlarmsBetDoughnutCancelDashboard:::', data)
      this.processNextChartData(data)
    })
  },
  mounted() {
    this.fetchData()
  },
  setup() {
    return {
      avatarText,
    }
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchRequestAlarmsBetFinish: FETCH_REQUEST_ALARMS_BET_FINISH,
    }),
    async fetchData() {
      if (this.periodTo === undefined) {
        this.periodTo = this.periodFrom
      }
      const periodFromWithTime = moment(this.periodFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      const periodToWithTime = moment(this.periodTo).endOf('day').format('YYYY-MM-DD HH:mm:ss')

      // 시, 분, 초가 추가된 날짜를 다시 변수에 할당
      this.periodFrom = periodFromWithTime
      this.periodTo = periodToWithTime

      await this.fetchRequestAlarmsBetFinishData()
      await this.sprepareChartData()
    },
    async fetchRequestAlarmsBetFinishData() {
      const mySite = this.siteSelected
      await this.$fetchRequestAlarmsBetFinish({
        site: mySite,
        periodFrom: this.periodFrom,
        periodTo: this.periodTo,
        confirmed: 'BETTING',
      })
      console.log('this.fetchRequestAlarmsBetFinish:::', this.fetchRequestAlarmsBetFinish)
    },
    async sprepareChartData() {
      // 데이터의 총 건수와 requestCash의 합을 저장할 객체
      const categorySummary = {}

      // 배열을 순회하면서 category를 기준으로 총 건수와 betCash의 합을 계산
      this.fetchRequestAlarmsBetFinish.forEach(entry => {
        const { category } = entry
        const { betCash } = entry

        // 해당 카테고리가 이미 객체에 존재하는지 확인
        if (!categorySummary.hasOwnProperty(category)) {
          // 존재하지 않는다면 새로운 카테고리를 추가하고 초기화
          categorySummary[category] = {
            totalEntries: 0,
            totalBetCash: 0,
          }
        }

        // 해당 카테고리의 총 건수와 betCash를 업데이트
        categorySummary[category].totalEntries += 1
        categorySummary[category].totalBetCash += betCash
      })

      const categoryOrder = ['Sports-Prematch', 'Sports-Inplay', 'Casino', 'Casino-Slot']

      // 정의된 순서에 따라 데이터를 가져오고 정렬
      const seriesData = categoryOrder.map(category => ({
        value: categorySummary[category] ? categorySummary[category].totalBetCash : 0,
        name: category,
      }))
      // console.log('seriesData:::', seriesData)
      // series 데이터의 value를 금액 형식으로 변환
      // const formattedSeriesData = seriesData.map(item => ({
      //   value: item.value.toLocaleString(), // 숫자를 금액 형식의 지역화된 문자열로 변환
      //   name: item.name,
      // }))
      // console.log('formattedSeriesData:::', formattedSeriesData)
      // Vue의 데이터 바인딩을 사용하여 series 데이터를 업데이트합니다.
      this.series[0].data = seriesData.map(item => ({
        value: item.value,
        name: item.name,
        label: {
          formatter(params) {
            return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          },
        },
      }))

      this.allBetCash = this.fetchRequestAlarmsBetFinish.reduce((total, entry) => total + entry.betCash, 0)

      console.log('Total Bet Cash:', this.allBetCash)
    },
    async processNextChartData(data) {
      // console.log('data:::', data)
      // console.log('00000 this.fetchRequestAlarmsBetFinish:::', this.fetchRequestAlarmsBetFinish)
      if (data.confirmed === 'YET' && data.betResult === null && data.confirmAt === null) {
        // this.fetchRequestAlarmsBetFinish.push(data)
        // console.log('11111 data:::', this.fetchRequestAlarmsBetFinish)
      }
      // if (data.confirmed === 'USER CANCELED' || data.confirmed === 'CUSTOM CANCELED' || data.confirmed === 'REJECT') {
      //   console.log('22222 data:::', data)
      //   for (let i = this.fetchRequestAlarmsBetFinish.length - 1; i >= 0; i -= 1) {
      //     if (this.fetchRequestAlarmsBetFinish[i].groupId === data.groupId) {
      //       console.log('33333 data:::', data.groupId)
      //       this.fetchRequestAlarmsBetFinish.splice(i, 1)
      //     }
      //   }
      // }
      // await this.sprepareChartData()
    },
    handleDateChange(selectedDates, dateStr, instance) {
      this.$nextTick(() => {
        // selectedDates 배열의 길이가 2인 경우에만 fetchData 함수 호출
        if (selectedDates.length === 2) {
          this.fetchData()
        }
      })
    },
  },
}
</script>

<style lang="scss">
.card-body{
  position: relative;
  .pie-text{
    width: 105px;
    position:absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
  }
.single-line-text {
  white-space: nowrap; /* 텍스트 줄 바꿈 비활성화 */
}
</style>
