<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          Profit Summary
        </b-card-title>
        <b-card-sub-title>수익 통계</b-card-sub-title>
      </div>
    </b-card-header>
    <b-card-body>
      <div
        class="transaction-item"
        @mouseover="isHovered = true"
        @mouseout="isHovered = false"
        @click="goRoute('deposit-list')"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              variant="secondary"
            >
              <feather-icon
                size="18"
                icon="CornerRightDownIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              금일 충전 총액
            </h6>
            <small>Deposit</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :style="{
            color: '#33d918'
          }"
        >
          ₩ {{ todayTotalDepositCash.toLocaleString() }}
        </div>
      </div>
      <div
        class="transaction-item"
        @mouseover="isHovered = true"
        @mouseout="isHovered = false"
        @click="goRoute('exchange-list')"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              variant="secondary"
            >
              <feather-icon
                size="18"
                icon="CornerRightUpIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              금일 환전 총액
            </h6>
            <small>Exchange</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :style="{
            color: '#ff3a3a'
          }"
        >
          ₩ {{ todayTotalExchangeCash.toLocaleString() }}
        </div>
      </div>

      <div
        class="transaction-item"
        @mouseover="isHovered = true"
        @mouseout="isHovered = false"
        @click="goRoute('betting-list')"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              variant="secondary"
            >
              <feather-icon
                size="18"
                icon="BoldIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              금일 배팅 총액
            </h6>
            <small>Betting</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :style="{
            color: '#33d918'
          }"
        >
          ₩ {{ todayTotalBettingCash.toLocaleString() }}
        </div>
      </div>

      <div
        class="transaction-item"
        @mouseover="isHovered = true"
        @mouseout="isHovered = false"
        @click="goRoute('refund-hit-result')"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              variant="secondary"
            >
              <feather-icon
                size="18"
                icon="AwardIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              금일 당첨 총액
            </h6>
            <small>HitResult</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :style="{
            color: '#ff3a3a'
          }"
        >
          ₩ {{ todayTotalWinCash.toLocaleString() }}
        </div>
      </div>

      <div
        class="transaction-item"
        @mouseover="isHovered = true"
        @mouseout="isHovered = false"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              variant="secondary"
            >
              <feather-icon
                size="18"
                icon="DollarSignIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              금일 수익 총액
            </h6>
            <small>Profit</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :style="{
            color: '#f1c346'
          }"
        >
          ₩ {{ todayTotalProfitCash.toLocaleString() }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BCardSubTitle,
} from 'bootstrap-vue'

import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_REQUEST_ALARMS_BET_FINISH,
  FETCH_REQUEST_ALARMS_DEPOSIT_FINISH,
  FETCH_REQUEST_ALARMS_EXCHANGE_FINISH,
} from '@/store/notice/action'

import * as moment from 'moment-timezone'
import Ripple from 'vue-ripple-directive'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'

const noticeStore = createNamespacedHelpers('noticeStore')

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BCardSubTitle,
  },
  directives: {
    Ripple,
  },
  props: {
    siteSelected: String,
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      todayTotalDepositCash: 0,
      todayTotalExchangeCash: 0,
      todayTotalBettingCash: 0,
      todayTotalWinCash: 0,
      todayTotalProfitCash: 0,
      isHovered: false,
      transactionData: [
        {
          mode: '금일 충전 총액',
          types: 'Deposit',
          avatar: 'CornerRightDownIcon',
          avatarVariant: 'light-primary',
          payment: this.todayTotalDepositCash,
          deduction: false,
        },
        {
          mode: '금일 환전 총액',
          types: 'Exchange',
          avatar: 'CornerRightUpIcon',
          avatarVariant: 'light-info',
          payment: this.todayTotalExchangeCash,
          deduction: true,
        },
        {
          mode: '금일 배팅 총액',
          types: 'Betting',
          avatar: 'BoldIcon',
          avatarVariant: 'light-warning',
          payment: this.todayTotalBettingCash,
          deduction: false,
        },
        {
          mode: '금일 당첨 총액',
          types: 'Win',
          avatar: 'AwardIcon',
          avatarVariant: 'light-warning',
          payment: this.todayTotalWinCash,
          deduction: true,
        },
        {
          mode: '금일 수익 총계',
          types: 'Profit',
          avatar: 'DollarSignIcon',
          avatarVariant: 'light-primary',
          payment: this.todayTotalProfitCash,
          deduction: null,
        },
      ],
    }
  },
  watch: {
    siteSelected: {
      handler(event) {
        // console.log('event ::', event)
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
    rangePicker() {
      const range = this.rangePicker.split(' to ')
      this.periodFrom = range[0]
      this.periodTo = range[1]
      this.fetchData()
    },
  },
  created() {
    // 금일 충전 총액
    this.$socket.on('requestAlarmsDepositProfitDashboard', data => {
      // console.log('requestAlarmsDepositProfitDashboard: ', data)
      this.todayTotalDepositCash = data.reduce((total, item) => total + item.requestCash, 0)
      this.todayTotalProfitCash = (this.todayTotalDepositCash) - (this.todayTotalExchangeCash)
      // console.log('총 todayTotalDepositCash 합계:', this.todayTotalDepositCash)
    })
    // 금일 환전 총액
    this.$socket.on('requestAlarmsExchangeProfitDashboard', data => {
      // console.log('requestAlarmsExchangeProfitDashboard: ', data)
      this.todayTotalExchangeCash = data.reduce((total, item) => total + item.requestCash, 0)
      this.todayTotalProfitCash = (this.todayTotalDepositCash) - (this.todayTotalExchangeCash)
      // console.log('총 todayTotalExchangeCash 합계:', this.todayTotalExchangeCash)
      // this.$store.dispatch('noticeStore/socketFetchRequestAlarmsExchangeFinish', data)
    })
    // 금일 배팅 총액
    this.$socket.on('requestAlarmsBetProfitDashboard', data => {
      // console.log('requestAlarmsBetProfitDashboard: ', data)
      // this.$store.dispatch('noticeStore/socketFetchRequestAlarmsBet', data)
      this.todayTotalBettingCash = data.reduce((total, item) => total + item.betCash, 0)
    })
    // 금일 배팅 총액 취소
    this.$socket.on('requestAlarmsBetCancelProfitDashboard', data => {
      // console.log('requestAlarmsBetProfitDashboard: ', data)
      // this.$store.dispatch('noticeStore/socketFetchRequestAlarmsBet', data)
      // this.todayTotalBettingCash = data.reduce((total, item) => total + item.betCash, 0)
      if (data && data.betCash) {
        this.todayTotalBettingCash -= data.betCash
      } else {
        console.error('Invalid data format for requestAlarmsBetCancelProfitDashboard:', data)
      }
    })
    // 금일 당첨결과 조회
    this.$socket.on('requestAlarmsBetWinDashboard', data => {
      // console.log('requestAlarmsBetWinDashboard: ', data)
      // this.$store.dispatch('noticeStore/socketFetchRequestAlarmsBet', data)
      this.todayTotalWinCash = data.reduce((total, item) => total + item.totalHitCash, 0)
    })
  },
  computed: {
    ...noticeStore.mapGetters({
      fetchRequestAlarmsDepositFinish: 'fetchRequestAlarmsDepositFinish',
      fetchRequestAlarmsExchangeFinish: 'fetchRequestAlarmsExchangeFinish',
      fetchRequestAlarmsBetFinish: 'fetchRequestAlarmsBetFinish',
    }),
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchRequestAlarmsDepositFinish: FETCH_REQUEST_ALARMS_DEPOSIT_FINISH,
      $fetchRequestAlarmsExchangeFinish: FETCH_REQUEST_ALARMS_EXCHANGE_FINISH,
      $fetchRequestAlarmsBetFinish: FETCH_REQUEST_ALARMS_BET_FINISH,
    }),
    async fetchData() {
      const mySite = this.siteSelected
      const periodFrom = moment().startOf('day').format(fmt1)
      const periodTo = moment().endOf('day').format(fmt1)
      const confirmAtFrom = moment().startOf('day').format(fmt1)
      const confirmAtTo = moment().endOf('day').format(fmt1)
      await this.$fetchRequestAlarmsDepositFinish({
        site: mySite,
        periodFrom,
        periodTo,
        confirmed: 'CONFIRMED',
      })
      this.todayTotalDepositCash = this.fetchRequestAlarmsDepositFinish.reduce((total, record) => total + record.requestCash, 0)
      // console.log('this.transactionData[0].payment22222:', this.transactionData[0].payment)
      await this.$fetchRequestAlarmsExchangeFinish({
        site: mySite,
        periodFrom,
        periodTo,
        confirmed: 'CONFIRMED',
      })
      this.todayTotalExchangeCash = this.fetchRequestAlarmsExchangeFinish.reduce((total, record) => total + record.requestCash, 0)
      // this.transactionData[1].payment = this.todayTotalExchangeCash.toLocaleString()
      // this.transactionData[4].payment = this.todayTotalProfitCash.toLocaleString()
      await this.$fetchRequestAlarmsBetFinish({
        site: mySite,
        periodFrom,
        periodTo,
        confirmed: 'BETTING',
      })
      this.todayTotalBettingCash = this.fetchRequestAlarmsBetFinish.reduce((total, record) => total + record.betCash, 0)
      // this.transactionData[2].payment = this.todayTotalBettingCash.toLocaleString()
      await this.$fetchRequestAlarmsBetFinish({
        site: mySite,
        // periodFrom,
        // periodTo,
        confirmAtFrom: periodFrom,
        confirmAtTo: periodTo,
        confirmed: 'CONFIRMED',
        betResult: 'WIN',
      })
      this.todayTotalWinCash = this.fetchRequestAlarmsBetFinish.reduce((total, record) => total + record.totalHitCash, 0)
      // this.transactionData[3].payment = this.todayTotalWinCash.toLocaleString()
      this.todayTotalProfitCash = (this.todayTotalDepositCash) - (this.todayTotalExchangeCash)
    },
    goRoute(list) {
      this.$router.push({ name: list }).catch(() => {})
    },
  },
}
</script>

<style scoped>
.transaction-item {
  transition: background-color 0.3s;
}

.transaction-item:hover {
  background-color: #252B3D; /* 호버 효과에 적용할 배경색상 */
}
</style>
