<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Deposit/Exchange Chart
        </b-card-title>
        <b-card-sub-title>충/환전 라인차트</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range', onChange: handleDateChange }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          style="width: 210px;"
          placeholder="YYYY-MM-DD"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <vue-apex-charts
        type="area"
        height="400"
        :options="lineAreaChartSpline.chartOptions"
        :series="lineAreaChartSpline.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

import * as moment from 'moment-timezone'

import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_REQUEST_ALARMS_DEPOSIT_FINISH,
  FETCH_REQUEST_ALARMS_EXCHANGE_FINISH,
} from '@/store/notice/action'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

const noticeStore = createNamespacedHelpers('noticeStore')

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    siteSelected: String,
  },
  computed: {
    ...noticeStore.mapGetters({
      fetchRequestAlarmsDepositFinish: 'fetchRequestAlarmsDepositFinish',
      fetchRequestAlarmsExchangeFinish: 'fetchRequestAlarmsExchangeFinish',
    }),
  },
  watch: {
    siteSelected: {
      handler(event) {
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
    rangePicker() {
      const range = this.rangePicker.split(' to ')
      this.periodFrom = range[0]
      this.periodTo = range[1]
      // this.fetchData()
    },
  },
  created() {
    // 누적 수익 차트 소켓 조회
    this.$socket.on('requestAlarmsDepositAreaChartDashBoard', data => {
      // console.log('requestAlarmsDepositAreaChartDashBoard: ', data)
      this.processNextChartData(data) // 다음 작업을 호출
    })
    this.$socket.on('requestAlarmsExchangeAreaChartDashBoard', data => {
      // console.log('requestAlarmsExchangeAreaChartDashBoard: ', data)
      this.processNextChartData(data) // 다음 작업을 호출
    })
  },
  mounted() {
    this.fetchData()
  },
  data() {
    const startOfMonth = moment(new Date()).startOf('month').format(fmt1)
    const endOfMonth = moment(new Date()).endOf('month').format(fmt1)
    const monthDays = []

    // 'startOfMonth'부터 'endOfMonth'까지의 각 날짜를 'MM-DD' 형식으로 배열에 추가
    for (let date = moment(startOfMonth); date.isSameOrBefore(endOfMonth); date.add(1, 'day')) {
      monthDays.push(date.format('M/D'))
    }
    return {
      periodFrom: startOfMonth,
      periodTo: endOfMonth,
      lineAreaChartSpline: {
        series: [
          {
            name: '충전',
            data: [110, 130, 95, 180, 300, 170, 150, 260, 240, 200, 160, 300, 395,
              210, 160, 80, 220, 160, 240, 290, 310, 310, 360, 340, 620, 350,
              300, 310, 370],
          },
          {
            name: '환전',
            data: [100, 120, 90, 130, 130, 70, 40, 140, 220, 180, 270, 280, 310,
              200, 150, 190, 210, 160, 230, 500, 300, 320, 260, 330, 200, 140,
              290, 310, 360],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: false,
            curve: 'straight',
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          xaxis: {
            categories:
              monthDays,
            labels: {
              rotate: -45, // 레이블을 -45도 회전
            },
          },
          yaxis: {
            labels: {
              formatter(value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              },
            },
          },
          fill: {
            opacity: 0.8,
            type: ['solid', 'solid'],
            gradient: {
              shade: 'light',
              type: 'vertical',
              shadeIntensity: 0.5,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 0.9,
              opacityTo: 0.8,
              stops: [0, 50, 100],
              colorStops: [],
            },
            pattern: {
              style: 'squares',
              width: 3,
              height: 3,
              strokeWidth: 1,
            },
          },
          tooltip: {
            shared: true,
          },
          colors: ['#3ef54c', '#ff3270'],
        },
      },
      rangePicker: [startOfMonth, endOfMonth],
    }
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchRequestAlarmsDepositFinish: FETCH_REQUEST_ALARMS_DEPOSIT_FINISH,
      $fetchRequestAlarmsExchangeFinish: FETCH_REQUEST_ALARMS_EXCHANGE_FINISH,
    }),
    async fetchData() {
      if (this.periodTo === undefined) {
        this.periodTo = this.periodFrom
      }
      const periodFromWithTime = moment(this.periodFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      const periodToWithTime = moment(this.periodTo).endOf('day').format('YYYY-MM-DD HH:mm:ss')

      // 시, 분, 초가 추가된 날짜를 다시 변수에 할당
      this.periodFrom = periodFromWithTime
      this.periodTo = periodToWithTime

      await this.fetchDepositFinish()
      await this.fetchExchangeFinish()
      await this.sprepareChartData()
    },
    async fetchDepositFinish() {
      const mySite = this.siteSelected
      await this.$fetchRequestAlarmsDepositFinish({
        site: mySite,
        confirmed: 'CONFIRMED',
        periodFrom: this.periodFrom,
        periodTo: this.periodTo,
      })
      // console.log('$fetchRequestAlarmsDepositFinish::', this.fetchRequestAlarmsDepositFinish)
    },
    async fetchExchangeFinish() {
      const mySite = this.siteSelected
      await this.$fetchRequestAlarmsExchangeFinish({
        site: mySite,
        confirmed: 'CONFIRMED',
        periodFrom: this.periodFrom,
        periodTo: this.periodTo,
      })
      // console.log('$fetchRequestAlarmsExchangeFinish::', this.fetchRequestAlarmsExchangeFinish)
    },
    // 데이터 가공
    async sprepareChartData() {
      // startOfMonth부터 endOfMonth까지의 depositFinish 데이터 필터링
      const filteredDepositFinish = this.fetchRequestAlarmsDepositFinish.filter(entry => moment(entry.confirmAt).isBetween(this.periodFrom, this.periodTo, null, '[]'))

      // startOfMonth부터 endOfMonth까지의 exchangeFinish 데이터 필터링
      const filteredExchangeFinish = this.fetchRequestAlarmsExchangeFinish.filter(entry => moment(entry.confirmAt).isBetween(this.periodFrom, this.periodTo, null, '[]'))

      // startOfMonth부터 endOfMonth까지의 날짜 목록 생성
      const dateRange = []
      const currentDate = moment(this.periodFrom)
      while (currentDate.isSameOrBefore(this.periodTo)) {
        dateRange.push(currentDate.format('M/D'))
        currentDate.add(1, 'day')
      }

      // depositFinish에서 confirmAt과 requestCash를 추출하여 가공
      const depositData = dateRange.map(date => {
        const matchingEntries = filteredDepositFinish.filter(entry => moment(entry.confirmAt).format('M/D') === date)
        const totalRequestCash = matchingEntries.reduce((sum, entry) => sum + entry.requestCash, 0)
        return {
          x: date,
          y: totalRequestCash,
        }
      })

      // exchangeFinish에서 confirmAt과 requestCash를 추출하여 가공
      const exchangeData = dateRange.map(date => {
        const matchingEntries = filteredExchangeFinish.filter(entry => moment(entry.confirmAt).format('M/D') === date)
        const totalRequestCash = matchingEntries.reduce((sum, entry) => sum + entry.requestCash, 0)
        return {
          x: date,
          y: totalRequestCash,
        }
      })

      // lineAreaChartSpline의 series에 데이터 적용
      this.lineAreaChartSpline.series = [
        {
          name: '충전',
          data: depositData,
        },
        {
          name: '환전',
          data: exchangeData,
        },
      ]
    },
    async processNextChartData(data) {
      await this.sprepareChartData()
    },
    handleDateChange(selectedDates, dateStr, instance) {
      this.$nextTick(() => {
        // selectedDates 배열의 길이가 2인 경우에만 fetchData 함수 호출
        if (selectedDates.length === 2) {
          this.fetchData()
        }
      })
    },
  },
}

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
