<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          User Connection Scatter Chart
        </b-card-title>
        <b-card-sub-title>유저접속 산점차트</b-card-sub-title>
        <!--          {{ fetchSigninLogsItems }}-->
      </div>
      <!--/ title and subtitle -->
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range', onChange: handleDateChange }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          style="width: 210px;"
          placeholder="YYYY-MM-DD"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="scatter"
        height="400"
        :options="scatterChart.chartOptions"
        :series="scatterChart.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle, BButton, BButtonGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

import { FETCH_SIGNIN_LOGS } from '@/store/logs/action'
import { createNamespacedHelpers } from 'vuex'

import * as moment from 'moment-timezone'

const userSigninLogStore = createNamespacedHelpers('logStore')
moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardSubTitle,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BButton,
    BButtonGroup,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    siteSelected: String,
  },
  computed: {
    ...userSigninLogStore.mapGetters([
      'fetchSigninLogsItems',
    ]),
  },
  watch: {
    siteSelected: {
      handler(event) {
        // console.log('event ::', event)
        this.fetchSigninLogs()
      },
      immediate: false,
      deep: false,
    },
    rangePicker() {
      const range = this.rangePicker.split(' to ')
      this.periodFrom = range[0]
      this.periodTo = range[1]
      // this.fetchSigninLogs()
    },
  },
  // created() {
  //   this.$socket.on('userSignLogsScatterChartDashBoard', data => {
  //     console.log('userSignLogsScatterChartDashBoard: ', data)
  //     this.processNextChartData(data) // 다음 작업을 호출
  //   })
  // },
  mounted() {
    this.fetchSigninLogs()
  },
  data() {
    const startOfMonth = moment(new Date()).startOf('month').format(fmt1)
    const endOfMonth = moment(new Date()).endOf('month').format(fmt1)
    const monthDays = []
    for (let date = moment(startOfMonth); date.isSameOrBefore(endOfMonth); date.add(1, 'day')) {
      monthDays.push(date.format('M/D'))
    }

    return {
      periodFrom: startOfMonth,
      periodTo: endOfMonth,
      scatterChart: {
        series: [
          {
            name: 'Android',
            data: [],
          },
          {
            name: 'Apple',
            data: [],
          },
          {
            name: 'PC',
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: true,
              type: 'xy',
            },
            toolbar: {
              show: true,
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          colors: ['#ff3f9e', '#61eafa', '#e5e377'],
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
          },
          xaxis: {
            type: 'datetime',
            labels: {
              format: 'M/d',
            },
            // tickAmount: 31,
            // labels: {
            //   formatter(val) {
            //     return moment(val).format('M/D')
            //   },
            // },
          },
          yaxis: {
            type: 'datetime', // 시간으로 설정
            min: 0,
            max: 24,
            tickAmount: 12, // 24시간 간격
            // labels: {
            //   formatter(value) {
            //     let formattedValue = (value < 10 ? '0' : '') + value
            //     formattedValue = `${formattedValue.toLocaleString()}:00`
            //     return formattedValue // Y축 라벨 포맷 설정
            //   },
            // },
          },
          tooltip: {
            x: {
              formatter(val) {
                return moment(val).format('YYYY-MM-DD HH:mm:ss')
              },
            },
            y: {
              formatter(val) {
                return ''
              },
              title: {
                formatter: seriesName => seriesName,
              },
            },
            z: {
              formatter: undefined,
              title: 'userid: ',
            },
          },
        },
      },
      rangePicker: [startOfMonth, endOfMonth],
    }
  },
  methods: {
    ...userSigninLogStore.mapActions({
      $fetchSigninLogs: FETCH_SIGNIN_LOGS,
    }),
    async fetchSigninLogs() {
      if (this.periodTo === undefined) {
        this.periodTo = this.periodFrom
      }
      const periodFromWithTime = moment(this.periodFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      const periodToWithTime = moment(this.periodTo).endOf('day').format('YYYY-MM-DD HH:mm:ss')

      // 시, 분, 초가 추가된 날짜를 다시 변수에 할당
      this.periodFrom = periodFromWithTime
      this.periodTo = periodToWithTime

      await this.$fetchSigninLogs({
        site: this.siteSelected,
        periodFrom: this.periodFrom,
        periodTo: this.periodTo,
      })
      await this.sprepareChartData()
    },

    // 데이터 가공
    async sprepareChartData() {
      const signinLogs = this.fetchSigninLogsItems
      const seriesData = {
        Android: [],
        Apple: [],
        PC: [],
      }
      // 데이터를 시간대별로 그룹화하고 각 플랫폼별로 카운트합니다.
      signinLogs.forEach(log => {
        const { platform } = log
        const { os } = log
        const createdAt = moment(log.createdAt)
        const dateKey = createdAt.format('YYYY-MM-DD') // 날짜만 추출
        const timeKey = createdAt.format('HH:mm') // 시간만 추출
        // 시간대와 플랫폼에 따라 데이터 가공
        if (platform === 'phone') {
          if (os === 'Android') {
            seriesData.Android.push([`${dateKey} ${timeKey}`, timeKey, log.userid])
          } else if (os === 'iOS') {
            seriesData.Apple.push([`${dateKey} ${timeKey}`, timeKey, log.userid])
          }
        } else if (platform === 'desktop') {
          seriesData.PC.push([`${dateKey} ${timeKey}`, timeKey, log.userid])
        }
      })
      // 차트 데이터 형식에 맞게 변환
      const chartData = Object.keys(seriesData).map(key => ({
        name: key,
        data: seriesData[key],
      }))
      this.scatterChart.series = chartData
      // console.log('chartData ::', chartData)
      // y축 설정
      this.scatterChart.chartOptions.yaxis = {
        type: 'datetime', // y축 데이터 타입을 날짜와 시간으로 설정
        labels: {
          formatter(value) {
            return moment(value).format('YYYY-MM-DD') // y축 라벨 포맷 설정 (날짜만 표시)
          },
        },
        tickAmount: 'data', // y축의 틱 개수를 데이터의 개수에 맞추도록 설정
      }
    },
    async processNextChartData(data) {
      this.fetchSigninLogsItems.push(data)
      await this.sprepareChartData()
    },
    handleDateChange(selectedDates, dateStr, instance) {
      this.$nextTick(() => {
        // selectedDates 배열의 길이가 2인 경우에만 fetchData 함수 호출
        if (selectedDates.length === 2) {
          this.fetchSigninLogs()
        }
      })
    },
  },
}
</script>
